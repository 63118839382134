import { ApolloError } from '@apollo/client';
import { LinearProgress, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (theme: Theme) => ({
  overviewMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  overviewMessage: {
    alignSelf: 'center',
    textAlign: 'center',
    width: 350,
    maxWidth: '90%',
  },
  message: {
    paddingBottom: theme.spacing(2),
  },
});

interface Properties {
  loading: boolean;
  error?: ApolloError | Error | false;
  data?: any;
  loadingText?: string | React.ReactNode;
  wrappedMessage?: React.ReactNode;
  withoutHeight?: boolean;
  height?: string;
}

class LoadingOrError extends React.Component<Properties & WithTranslation & WithStyles<typeof styles>> {
  public render() {
    const { classes, t, loading, loadingText, error, data, children, wrappedMessage, withoutHeight } = this.props;

    const height = withoutHeight ? 'auto' : this.props.height ?? '95%';
    if (loading) {
      return (
        <div className={classes.overviewMessageContainer} style={{ height }}>
          <div className={classes.overviewMessage}>
            <Typography variant="body1" className={classes.message}>
              {loadingText ? loadingText : t(['shared:loading'], { defaultValue: 'Loading...' })}
            </Typography>
            <LinearProgress color="primary" />
          </div>
        </div>
      );
    }
    // We only short-circuit the render if the query is loading or returned only an error (i.e. not an error + data).
    if (error && !data) {
      let errorMessage: React.ReactNode;
      switch (error.message) {
        default:
          errorMessage = (
            <Typography variant="body2">
              {t(['shared:errorOcurredTryReloading'], {
                defaultValue: 'An error occurred! Please try reloading the page.',
              })}
            </Typography>
          );
          break;
      }
      return (
        <div className={classes.overviewMessageContainer} style={{ height }}>
          <div className={classes.overviewMessage}>
            <Typography variant="h2" className={classes.message}>
              {':('}
            </Typography>
            {errorMessage}
          </div>
        </div>
      );
    }
    if (wrappedMessage) {
      return (
        <div className={classes.overviewMessageContainer} style={{ height }}>
          <div className={classes.overviewMessage}>{wrappedMessage}</div>
        </div>
      );
    }
    if (children) {
      // return null;
      return children;
    }
    return null;
  }
}
const enhance = compose<unknown, Properties>(withTranslation(['shared']), withStyles(styles));
export default enhance(LoadingOrError as React.ComponentType<unknown>);
