import { useCallback, useState } from 'react';
import Cookies from 'universal-cookie';

const uc = new Cookies();

const useCookie = (key: string, defaultValue: string) => {
  const [cookie, setCookie] = useState<string>(uc.get(key) || defaultValue);

  const updateCookie = useCallback((value: string, age: number = (7).days) => {
    setCookie(value);
    uc.set(key, value, { maxAge: age });
  }, []);

  return [cookie, updateCookie] as [typeof cookie, typeof updateCookie];
};

export default useCookie;
