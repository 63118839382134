import { useRouter } from 'next/router';
import { parse } from 'querystring';
import { useMemo } from 'react';

/**
 * Can't reliably extract QPs from Next's useRouter on initial client-side render.
 * [Issue](https://github.com/vercel/next.js/issues/10521)
 */
export const useURLQuery = () => {
  const router = useRouter();

  const queryParams = useMemo(() => {
    const [, pathStr] = router.asPath.split('?');

    return Object.entries(parse(pathStr)).reduce((normalizedQuery, [key, value]) => {
      const [primaryValue] = Array.isArray(value) ? value : [value];

      normalizedQuery[key] = primaryValue;

      return normalizedQuery;
    }, {} as Record<string, string | undefined>);
  }, [router.asPath]);

  return queryParams;
};
