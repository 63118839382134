import { sleep } from '@blackbird/common/sleep';
import { FC, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

// RESOURCES:
// https://web.dev/serial/
// https://reillyeon.github.io/serial/#onconnect-attribute-0
// https://codelabs.developers.google.com/codelabs/web-serial
// https://gist.github.com/joshpensky
import { serialSupport } from '@/browser-features';

export type PortState = 'closed' | 'closing' | 'open' | 'opening';

const regexFloatingPointString = /[+-]?\d+(\.\d+)?(.*)/g;

export interface SerialContextValue {
  hasTriedAutoconnect: boolean;
  portState: PortState;
  connect(): Promise<boolean>;
  disconnect(): void;
  readWeight(): Promise<string | undefined>;
  isGrams: boolean;
}
const contextDefaults: SerialContextValue = {
  hasTriedAutoconnect: false,
  connect: () => Promise.resolve(false),
  disconnect: () => {},
  portState: 'closed',
  readWeight: () => Promise.resolve(''),
  isGrams: true,
};

export const SerialContext = createContext(contextDefaults);

export const useSerial = () => useContext(SerialContext);
// [83, 32, 10] = S, ,\n (S means get stable weight)
const getWeightCommand = new Uint8Array([83, 32, 10]);

const SerialProvider: FC = serialSupport
  ? ({ children }) => {
      const [portState, setPortState] = useState<PortState>('closed');
      const [hasTriedAutoconnect, setHasTriedAutoconnect] = useState(false);
      const [hasManuallyDisconnected, setHasManuallyDisconnected] = useState(false);
      const [connectRetries, setConnectRetries] = useState<number>(0);
      const [isGrams, setIsGrams] = useState<boolean>(true);

      const portRef = useRef<SerialPort | null>(null);

      const readerClosedPromiseRef = useRef<Promise<void>>(Promise.resolve());

      const decoder = useMemo(() => new TextDecoder(), []);
      /**
       * Writes the request to get the weight on the serial port and then
       * writes another request on the serial to get the recorded value
       *
       */
      const readWeight = async (): Promise<string | undefined> => {
        if (!portRef?.current) {
          return;
        }
        let writable: WritableStreamDefaultWriter | undefined, readable: ReadableStreamDefaultReader | undefined;

        try {
          writable = portRef?.current?.writable?.getWriter();
          await writable?.write(getWeightCommand);

          await sleep(1200);

          readable = portRef?.current?.readable?.getReader();
          const readerData = await readable?.read();

          const [weight, unit] =
            decoder.decode(readerData?.value).match(regexFloatingPointString)?.[0]?.split(' ') || [];

          if (!weight || !unit) {
            return;
          }

          switch (unit.trim()) {
            // TODO: When we support multiple units, probably store this as an enum, e.g. UNITS.GRAM
            case 'g':
              setIsGrams(true);
              break;
            default:
              return undefined;
          }

          return weight;
        } catch (error) {
          throw new Error(`Error reading from serial port: ${error}`);
        } finally {
          readable?.releaseLock();
          writable?.releaseLock();
        }
      };

      const openPort = async (port: SerialPort) => {
        try {
          await port.open({ baudRate: 9600 });
          portRef.current = port;
          setPortState('open');
          setHasManuallyDisconnected(false);
          setConnectRetries(0);
        } catch (error) {
          setPortState('closed');
          throw new Error(`Error opening serial port: ${error}`);
        }
      };

      const manualConnectToPort = async () => {
        if (portState === 'closed') {
          setPortState('opening');
          try {
            const port = await navigator.serial.requestPort();
            await openPort(port);
            return true;
          } catch (error) {
            setPortState('closed');
          }
        }
        return false;
      };

      const autoConnectToPort = async () => {
        if (portState === 'closed') {
          setPortState('opening');
          const availablePorts = await navigator.serial.getPorts();
          if (availablePorts.length) {
            const port = availablePorts[0];

            await openPort(port);
            return true;
          } else {
            setPortState('closed');
          }
          setHasTriedAutoconnect(true);
        }
        return false;
      };

      const manualDisconnectFromPort = async () => {
        if (portState === 'open') {
          const port = portRef.current;
          if (port) {
            setPortState('closing');
            await readerClosedPromiseRef.current;
            try {
              // Close and nullify the port
              await port.close();
              portRef.current = null;

              // Update port state
              setHasManuallyDisconnected(true);
              setHasTriedAutoconnect(false);
              setPortState('closed');
            } catch (error) {
              throw new Error(`Error disconnecting from serial port: ${error}`);
            }
          }
        }
      };

      /**
       * Event handler for when the port is disconnected unexpectedly.
       */
      const onPortDisconnect = async () => {
        // Wait for the reader to finish it's current loop
        await readerClosedPromiseRef.current;
        // Update state
        readerClosedPromiseRef.current = Promise.resolve();
        portRef.current = null;
        setHasTriedAutoconnect(false);
        setPortState('closed');
      };

      // Handles attaching the reader and disconnect listener when the port is open
      useEffect(() => {
        const port = portRef.current;
        if (portState === 'open' && port) {
          // Attach a listener for when the device is disconnected
          navigator.serial.addEventListener('disconnect', onPortDisconnect);

          return () => {
            navigator.serial.removeEventListener('disconnect', onPortDisconnect);
          };
        }
      }, [portState]);
      useEffect(() => {
        const connect = () => {
          if (portState === 'closed') {
            autoConnectToPort();
          }
        };

        const disconnect = () => {
          if (portState === 'open') {
            manualDisconnectFromPort();
          }
        };

        window.addEventListener('focus', connect);

        window.addEventListener('blur', disconnect);

        return () => {
          window.removeEventListener('focus', connect);
          window.removeEventListener('blur', disconnect);
        };
      });

      // Tries to auto-connect to a port, if possible
      useEffect(() => {
        if (hasManuallyDisconnected) {
          return;
        }

        if (hasTriedAutoconnect) {
          return;
        }

        if (portState === 'closed') {
          return;
        }

        setConnectRetries(connectRetries + 1);

        if (connectRetries < 3) {
          autoConnectToPort();
        }
      }, [hasManuallyDisconnected, hasTriedAutoconnect, portState]);

      return (
        <SerialContext.Provider
          value={{
            hasTriedAutoconnect,
            portState,
            connect: manualConnectToPort,
            disconnect: manualDisconnectFromPort,
            readWeight,
            isGrams,
          }}
        >
          {children}
        </SerialContext.Provider>
      );
    }
  : ({ children }) => <SerialContext.Provider value={contextDefaults}>{children}</SerialContext.Provider>;

export default SerialProvider;
