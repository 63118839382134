import { Grid, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { WithUser, withUser } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = () => ({
  fillHeight: {
    height: '100%',
  },
  progress: {
    position: 'absolute',
    textAlign: 'center',
    maxWidth: '400px',
    width: '20%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

class FillHeight extends React.Component<WithUser & WithTranslation & WithStyles<typeof styles>, {}> {
  render() {
    const { classes, children, user, t } = this.props;

    let fullLoading = true;
    if (user && user.status && user.status.fullLoading === false) {
      fullLoading = false;
    }

    return (
      <Grid item xs={12} className={classes.fillHeight}>
        {fullLoading ? (
          <div className={classes.progress}>
            <Typography variant="subtitle1">
              {t(['shared:initializingBlackbird'], { defaultValue: 'Initializing Blackbird...' })}
            </Typography>
            <LinearProgress variant={'indeterminate'} />
          </div>
        ) : (
          children
        )}
      </Grid>
    );
  }
}
const enhance = compose<unknown, Partial<WithUser & WithTranslation & WithStyles<typeof styles>>>(
  withUser,
  withTranslation(['shared']),
  withStyles(styles),
);
export default enhance(FillHeight as React.ComponentType<unknown>);
