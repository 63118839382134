import { Theme, useTheme } from '@mui/material/styles';
import React from 'react';
import { withStyles as _withStyles } from 'tss-react/mui';

// I cannot currently make this compile properly :/
// type Component<P> = React.ComponentType<P> | ((props: P) => JSX.Element);
type Component<_P> = any;

export type WithStyles<StylesFunction extends (...args: any) => any = () => {}> = {
  classes: Record<keyof ReturnType<StylesFunction>, string>;
  theme: Theme;
};

const ThemeWrapper = <P extends {} = {}>({
  WrappedComponent,
  props,
}: {
  WrappedComponent: Component<P & { theme?: Theme }>;
  props: P;
}): React.ReactElement<P> => {
  const theme = useTheme();

  return <WrappedComponent theme={theme} {...props} />;
};

// Unused "options" are for backwards compatibility
export const withStyles =
  <P extends {} = {}>(styles: (theme: Theme, props?: P) => any, _options?: { withTheme?: boolean; name?: string }) =>
  (Component: Component<P & { theme?: Theme }>): Component<P> => {
    const component: React.FC<P> = (props) => <ThemeWrapper WrappedComponent={Component} props={props} />;

    return _withStyles(component, styles);
  };
