import { FeatureMap } from '@/lib/features';

const staticFeatureMap = {
  'Alarms.Alarm': {
    Q: true,
    M: true,
  },
  'Andon.WorkOrder': {
    Q: true,
    M: true,
  },
  'Andon.Call.Create': {
    Q: true,
    M: true,
  },
  'Andon.Call.List': {
    Q: true,
    M: true,
  },
  'Andon.Call.Update': {
    Q: true,
    M: true,
  },
  'Andon.Support.Create': {
    Q: true,
    M: true,
  },
  'Andon.Support.List': {
    Q: true,
    M: true,
  },
  'Andon.Support.Update': {
    Q: true,
    M: true,
  },
  'Andon.Event.Attendance': {
    Q: true,
    M: true,
  },
  'Andon.Event': {
    Q: true,
    M: true,
  },
  'Andon.Tag.Create': {
    Q: true,
    M: true,
  },
  'Andon.Tag.List': {
    Q: true,
    M: true,
  },
  'Andon.Tag.Update': {
    Q: true,
    M: true,
  },
  'Andon.Subscriber.Create': {
    Q: true,
    M: true,
  },
  'Andon.Subscriber.List': {
    Q: true,
    M: true,
  },
  'Andon.Subscriber.Update': {
    Q: true,
    M: true,
  },
  'Batches.Batch': {
    Q: true,
    M: true,
  },
  'Batches.Packaging': {
    Q: false,
    M: true,
  },
  'Batches.Product': {
    Q: false,
    M: true,
  },
  'Devices.Device': {
    Q: true,
    M: true,
  },
  'Devices.Configuration': {
    Q: true,
    M: true,
  },
  'Devices.Sample': {
    Q: true,
    M: false,
  },
  'IAM.Group': {
    Q: false,
    M: true,
  },
  'IAM.Role': {
    Q: false,
    M: true,
  },
  'IAM.User': {
    Q: false,
    M: true,
  },
  'Lines.Line': {
    Q: true,
    M: true,
  },
  'Lines.Schedule': {
    Q: true,
    M: true,
  },
  'Lines.LineGroup': {
    Q: false,
    M: true,
  },
  'Lines.Test': {
    Q: false,
    M: true,
  },
  'Shifts.Shift': {
    Q: true,
    M: true,
  },
  'Stops.Stop': {
    Q: true,
    M: true,
  },
  'Stops.StopCause': {
    Q: false,
    M: true,
  },
  'Stops.StopCauseCategory': {
    Q: false,
    M: true,
  },
  'Stops.StopCauseMapping': {
    Q: true,
    M: true,
  },
  'Devices.Provision': {
    Q: true,
    M: true,
  },
};

export const staticFeatures = {
  group: {
    '3': staticFeatureMap,
  },
  line: {
    '1': staticFeatureMap,
    '2': staticFeatureMap,
    '3': staticFeatureMap,
  },
  peripheral: {
    '156-0': staticFeatureMap,
    '201-0': staticFeatureMap,
    '352-0': staticFeatureMap,
    '225-0': staticFeatureMap,
    '588-0': staticFeatureMap,
    '611-0': staticFeatureMap,
    '674-0': staticFeatureMap,
    '70-0': staticFeatureMap,
    '815-0': staticFeatureMap,
    '851-0': staticFeatureMap,
    '852-0': staticFeatureMap,
    '853-0': staticFeatureMap,
    '898-0': staticFeatureMap,
    '899-0': staticFeatureMap,
    'de465070-6024-11e9-b11e-5119ed89c76e': staticFeatureMap,
  },
  userPool: {
    'eu-west-1_Aw3f4EIcP': staticFeatureMap,
    'eu-west-1_aBWI4ripT': staticFeatureMap,
  },
};

export const emptyFeatureList: FeatureMap = {
  group: {},
  line: {},
  peripheral: {},
  userPool: {},
};
