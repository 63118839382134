import { Autorenew as AutorenewIcon, Close as CloseIcon } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const NewBuildSnackbar: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [currentBuildId, setBuildId] = useState('');

  const [t] = useTranslation(['shared']);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onClick = useCallback(() => {
    location.reload();
  }, []);

  // FIXME: Rewrite this to be timeout-based.
  useEffect(() => {
    let buildPollInterval: ReturnType<typeof setInterval>;

    buildPollInterval = setInterval(async () => {
      try {
        const response = await fetch('/BUILD_ID');

        if (response.status === 404) {
          return clearInterval(buildPollInterval);
        }

        const buildId = await response.text();

        setBuildId(() => {
          if (currentBuildId && currentBuildId !== buildId) {
            setOpen(true);
            clearInterval(buildPollInterval);
          }

          return buildId;
        });
      } catch {
        // Local dev
        console.warn('Was unable to fetch /BUILD_ID');
      }
    }, (5).minutes);

    return () => clearInterval(buildPollInterval);
  });

  return (
    <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} onClose={onClose}>
      <Alert
        action={
          <>
            <IconButton size="small" color="inherit" onClick={onClick}>
              <AutorenewIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" color="inherit" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        severity="info"
      >
        {t('shared:newBuildAvailable', 'A new build is available.')}
      </Alert>
    </Snackbar>
  );
};

export default NewBuildSnackbar;
