import { Language } from '@mui/icons-material';
import { Button, CircularProgress, Hidden, MenuItem, MenuList, Paper, Popover, Typography } from '@mui/material';
import { bindPopover, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '@/hooks/use-translation';
import languages from '@/languages.json';

type Languages = Record<string, { nativeName: string }>;

const useStyles = makeStyles()((theme) => ({
  button: {
    '&:hover *': {
      color: '#FFB200 !important',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
  text: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    textTransform: 'uppercase',
  },
  language: {
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  verticalAlignMiddle: {
    verticalAlign: 'middle',
  },
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  changeLanguageProgress: {
    color: '#ffb200',
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    zIndex: 1,
  },
}));

type LanguagePickerProps = {
  className?: string;
};

const LanguagePicker: React.FC<LanguagePickerProps> = ({ className }) => {
  const { i18n } = useTranslation();

  const availableLanguages = languages as Languages;

  const currentLanguage = (() => {
    const currentLanguage = (i18n && i18n.language) || 'en-GB';

    return availableLanguages[currentLanguage] ? currentLanguage : 'en-GB';
  })();

  const { cx, classes } = useStyles();

  const [isLoadingLanguage, setIsLoadingLanguage] = useState(false);

  const popupState = usePopupState({ popupId: 'language-picker', variant: 'popover' });

  const handleChangeLanguage = useCallback(
    (languageCode: any, cb: any) => {
      setIsLoadingLanguage(true);

      if (i18n) {
        // In the newest i18n version use promise instead of callback.
        i18n.changeLanguage(languageCode, (err) => {
          setIsLoadingLanguage(false);

          // Force reload to make sure language change takes effect.
          window.location.reload();

          if (err) {
            console.error(`Error changing language: ${err}`);
          }
        });
      }

      cb();
    },
    [i18n],
  );

  const handleLangClick = useCallback((lang: any, cb: any) => {
    return () => handleChangeLanguage(lang, cb);
  }, []);

  return (
    <div className={className}>
      <Button className={classes.button} {...bindToggle(popupState)}>
        <Typography className={classes.text} variant={'body2'}>
          <Hidden mdDown>
            <span className={cx(classes.language, classes.verticalAlignMiddle)}>
              {availableLanguages[currentLanguage].nativeName}
            </span>
          </Hidden>
          <span className={classes.wrapper}>
            <Language className={classes.verticalAlignMiddle} />
            {isLoadingLanguage && (
              <CircularProgress size={28} thickness={3} className={classes.changeLanguageProgress} />
            )}
          </span>
        </Typography>
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper sx={{ backgroundColor: 'white', color: 'black' }}>
          <MenuList>
            {Object.keys(availableLanguages).map((lang) => (
              <MenuItem key={lang} onClick={handleLangClick(lang, popupState.close)}>
                {availableLanguages[lang].nativeName}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popover>
    </div>
  );
};

export default LanguagePicker;
