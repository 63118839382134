/**
 * A feature, copied over from IAM.
 */
export interface FeatureValue {
  Q: boolean;
  M: boolean;
}

/**
 * A map of feature key to feature values, copied over from IAM.
 */
export interface FeatureKeys {
  [key: string]: FeatureValue;
}

/**
 * A full list of features across groups, lines and peripherals, copied over from IAM.
 */
export interface FeatureMap {
  group: { [key: string]: FeatureKeys };
  line: { [key: string]: FeatureKeys };
  peripheral: { [key: string]: FeatureKeys };
  userPool: { [key: string]: FeatureKeys };
}

/**
 * The GraphQL version of the IAM feature, copied over from Gateway.
 */
export interface GraphQLFeatureValue {
  key: string;
  Q: boolean;
  M: boolean;
}

/**
 * The GraphQL version of the IAM keys, copied over from Gateway.
 */
export interface GraphQLFeatureKey {
  id: string;
  feature: GraphQLFeatureValue[];
}

/**
 * The GraphQL version of the IAM feature list, copied over from Gateway.
 */
export interface GraphQLFeatureList {
  group: GraphQLFeatureKey[];
  line: GraphQLFeatureKey[];
  peripheral: GraphQLFeatureKey[];
  userPool: GraphQLFeatureKey[];
}

export type HasItemFeatures = (itemId: string, lookupFeature: string[], featureType: 'Q' | 'M') => boolean;

// TODO: Add memoization of lookups.
export const inAnyGroup =
  (features: FeatureMap) =>
  (lookupFeatures: string[], featureType: 'M' | 'Q'): boolean => {
    if (!features) {
      return false;
    }
    for (const groupFeatures of Object.values(features.group)) {
      if (groupFeatures) {
        for (const lookupFeature of lookupFeatures) {
          if (groupFeatures[lookupFeature] && groupFeatures[lookupFeature][featureType]) {
            return true;
          }
        }
      }
    }

    return false;
  };

// TODO: Add memoization of lookups.
export const inAnyUserPool =
  (features: FeatureMap) =>
  (lookupFeatures: string[], featureType: 'M' | 'Q'): boolean => {
    if (!features) {
      return false;
    }

    for (const userPoolFeatures of Object.values(features.userPool)) {
      if (userPoolFeatures) {
        for (const lookupFeature of lookupFeatures) {
          if (userPoolFeatures[lookupFeature] && userPoolFeatures[lookupFeature][featureType]) {
            return true;
          }
        }
      }
    }

    return false;
  };

// TODO: Add memoization of lookups.
export const generateForItem: (
  features: FeatureMap,
  featureGroup: 'group' | 'line' | 'peripheral' | 'userPool',
) => HasItemFeatures = (features, featureGroup) => (id, lookupFeatures, featureType) => {
  if (!features) {
    return false;
  }

  const itemFeatures = features[featureGroup][id];
  if (!itemFeatures) {
    return false;
  }

  for (const lookupFeature of lookupFeatures) {
    const feature = itemFeatures[lookupFeature];

    if (feature && feature[featureType]) {
      return true;
    }
  }

  return false;
};
