/**
 * A convenient way to overwrite properties of existing types, e.g. making them optional
 * or required.
 *
 * Example:
 * ```typescript
interface Person {
  id: number;
  name: string;
  hometown: string;
  nickname: string;
}

type PersonInput = Overwrite<Person, {
  id?: number;
  nickname?: string;
}>
```
 * `PersonInput` now has an optional id and nickname, instead of a required one.
 *
 * It works by keeping the keys that are in `T1`, iterating over its properties, and
 * creating an object with those types, and then adding `T2` as a union, via `&`.
 *
 * You can read more about conditional types here:
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html.
 */
export type Overwrite<T1, T2> = { [P in Exclude<keyof T1, keyof T2>]: T1[P] } & T2;

export type Merge<M, N> = Overwrite<M, Extract<keyof M, keyof N>> & N;

/**
 * A handy function to use as the default in a case/if statement, to make sure all
 * enumerations of a union type/enum is covered.
 *
 * Example:
 * ```typescript
const formatKey = (status: "ONGOING" | "STOPPED") => {
  switch (status) {
    case 'ONGOING':
      return 'Ongoing';
    case 'STOPPED':
      return 'Stopped';
    default:
      return assertUnreachable(status);
  }
}
```
 * In the above snippet, if we remove a `status` in the switch, or add a new `status`, and forget to
 * cover it, TypeScript will highlight an error on `assertUnreachable`, stating that argument
 * of type `something` is not assignable to type `never`.
 */
export const assertUnreachable = (x: never): never => {
  // throw new Error(`Didn't expect to get here - ${x}`);
  console.error(`[assertUnreachable] Didn't expect to get here - ${x}`);
  return false as never;
};

export const isTruthy = <T>(c: T): c is NonNullable<T> => !!c;
