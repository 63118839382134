import { Footer as BaseFooter, FooterLink } from '@blackbird/ui-base';

import { useTranslation } from '@/hooks/use-translation';
import { useVersion } from '@/hooks/use-version';

import SystemStatus from './system-status';

const infoSiteURI = 'https://www.blackbird.online';

type FooterProps = {
  showSupportLink?: boolean;
};

const Footer: React.FC<FooterProps> = ({ showSupportLink = false }) => {
  const { t } = useTranslation(['shared']);
  const buildInfo = useVersion();

  const links: FooterLink[] = [
    {
      href: 'https://status.blackbird.online',
      label: <SystemStatus />,
    },
    {
      href: '/getting-started',
      label: t('shared:gettingStarted', 'Getting started'),
    },
    {
      href: '/help',
      label: t('shared:help', 'Help'),
    },
    {
      href: 'https://www.notion.so/blackbirdhq/What-s-New-in-Blackbird-50a9562cf70d48429b5f359598645504',
      label: t('shared:whatsNew', "What's new"),
    },
    {
      href: `${infoSiteURI}/about`,
      label: t('shared:about', 'About'),
    },
    {
      href: `${infoSiteURI}/contact`,
      label: t('shared:contact', 'Contact'),
    },
  ];

  if (showSupportLink) {
    links.push({
      href: `${infoSiteURI}/support`,
      label: t('shared:support', 'Support'),
    });
  }

  return <BaseFooter links={links} buildInfo={buildInfo} />;
};

export default Footer;
