import { Breakpoint, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { useWidth } from '@/hooks';

export interface WithWidth {
  width: Breakpoint;
  fullScreen: boolean;
}

export const withWidth =
  () =>
  <T extends WithWidth>(WrappedComponent: React.ComponentType<T>): React.FunctionComponent =>
  (props) => {
    const theme = useTheme();
    const width = useWidth();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return <WrappedComponent {...(props as T)} width={width} fullScreen={fullScreen} />;
  };

const breakpoints: Record<Breakpoint, number> = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
};

export const isWidthUp = (compareTo: Breakpoint, width: Breakpoint): boolean =>
  breakpoints[width] >= breakpoints[compareTo];
export const isWidthDown = (compareTo: Breakpoint, width: Breakpoint): boolean =>
  breakpoints[width] <= breakpoints[compareTo];
