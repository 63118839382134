import { da, de, enGB, enUS, fr } from 'date-fns/locale';

export const locales: { [key: string]: Locale } = {
  da,
  de,
  enGB,
  enUS,
  fr,
};

type extendedWindow = Window & { __locale__?: string };

const getLocale = (locale?: string): Locale => {
  let selectedLocale = locale && locales[locale];

  // tslint:disable:no-empty
  try {
    selectedLocale = selectedLocale || locales[(window as extendedWindow).__locale__ as string];
  } catch (_) {}

  selectedLocale = selectedLocale || locales.enUS;

  return selectedLocale;
};

export default getLocale;
