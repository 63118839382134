import * as Schema from 'generated/graphql/schema';
import * as React from 'react';

import { FeatureMap } from '@/lib/features';

interface IStatus {
  loading: boolean;
  error?: Error;
  fullLoading?: boolean;
}

export interface IUserContext {
  status: IStatus;
  sub: string;
  company: {
    id: string;
    name: string;
  };
  emailVerified?: string;
  givenName?: string;
  familyName?: string;
  email: string;
  username: string;
  companyName?: string;
  userpoolID?: string;
  role?: string;
  features?: FeatureMap;
  claims: Schema.MeQuery['claims'];
}

const UserContext = React.createContext<IUserContext>({
  status: {
    loading: true,
    error: undefined,
  },
  company: { id: '', name: '' },
  sub: '',
  emailVerified: '',
  givenName: '',
  familyName: '',
  email: '',
  username: '',
  companyName: '',
  userpoolID: '',
  role: '',
  claims: { index: [], resources: [] },
});

export { UserContext };
