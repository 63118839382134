/*
 Generated by typeshare 1.5.0
*/

export interface FeatureValue {
  Q: boolean;
  M: boolean;
}

export type FeatureMap = Record<string, FeatureValue>;

export type Resource = string;

/**
 * This is the structure that is output from the authorizer.
 * This value can be retrieved in ms-graphql-gateway lambda in the event.requestContext.authorizer object
 *
 * The fields in the structure has to be of type String, number or boolean,
 * so to pass complex fields, we have to stringify anything more complex than those types.
 * We do that by base64 encoding a JSON string.
 * TODO: It should be sufficient to pass the JSON as a string. There is no need for base64 encoding it.
 * If we make this change, we have to update the decoding function in the gateway.
 *
 * After decoding the complex types, you will arrive at `AuthorizationContext`.
 *
 * See https://docs.aws.amazon.com/apigateway/latest/developerguide/api-gateway-lambda-authorizer-output.html for docs.
 */
export interface AuthorizationContextStringified {
  defaultLanguage: string;
  permissions: string;
  permissions2: string;
  userSub: string;
  userPool: string;
  assumingUser?: string;
}

export interface FeatureList {
  permittedGroup: Record<string, FeatureMap>;
  group: Record<string, FeatureMap>;
  line: Record<string, FeatureMap>;
  peripheral: Record<string, FeatureMap>;
  userPool: Record<string, FeatureMap>;
  scopeless: FeatureMap;
}

export enum Claim {
  /** This claim is attached to a Resource of type Module if the user has access to seeing the module. */
  ModuleAccess = 'moduleAccess',
  MaintenanceReadTaskConfiguration = 'maintenanceReadTaskConfiguration',
  MaintenanceWriteTaskConfiguration = 'maintenanceWriteTaskConfiguration',
  PeripheralReadConfiguration = 'peripheralReadConfiguration',
  PeripheralWriteConfiguration = 'peripheralWriteConfiguration',
}

export interface Permissions {
  claimIndex: Record<Claim, number>;
  resources: Record<Resource, number[]>;
}

export interface AuthorizationContext {
  defaultLanguage: string;
  permissions: FeatureList;
  permissions2: Permissions;
  userSub: string;
  userPool: string;
  assumingUser?: string;
}

export interface InvalidateAccessTokenEvent {
  accessTokenCookieValue: string;
}

export enum Module {
  Maintenance = 'maintenance',
}
