import { Provider, createContext, useContext } from 'react';

export const createContextHook = <T,>(defaultValue?: T): [Provider<T | undefined>, () => T] => {
  const context = createContext(defaultValue);

  return [
    context.Provider,
    () => {
      const value = useContext(context);

      if (!value) {
        throw new Error(`! ${context.displayName} was consumed without a provider`);
      }

      return value;
    },
  ];
};
