import { Lens as CircleIcon, Clear as ClearIcon, Done as DoneIcon, ChevronLeft as OpenIcon } from '@mui/icons-material';
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  Fab,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Theme,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import * as Schema from 'generated/graphql/schema';
import * as i18next from 'i18next';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import LoadingOrError from '@/components/loading/loading-or-error';
import { WithStyles, withStyles } from '@/hocs/with-styles';
import * as Types from '@/types';

const drawerWidth = 326;

const styles = (theme: Theme) => ({
  drawerPaper: {
    height: 'auto',
    width: drawerWidth,
    right: 0,
    top: 0,
    bottom: 0,
    paddingBottom: '64px',
    overflowY: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  toolbar: theme.mixins.toolbar,
  mobileOpenButton: {
    width: '64px',
    height: '64px',
    position: 'absolute',
    top: '0',
    right: '0',
  },
  nested: {
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(4),
  },
  buttonGroup: {
    marginLeft: 'auto',
    order: 2,
  },
  buttonGroupMobile: {
    marginLeft: 'auto',
    order: 2,
    marginRight: theme.spacing(-2),
  },
  fab: {
    position: 'sticky',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabContainer: {
    position: 'absolute',
    right: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  noLinesContainer: {
    display: 'flex',
    height: '95%',
    justifyContent: 'center',
  },
  noLines: {
    width: '350px',
    maxWidth: '90%',
    alignSelf: 'center',
    textAlign: 'center',
  },
  loadText: {
    paddingLeft: theme.spacing(0.5),
  },
  helpText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
  },
});

interface Props {
  groups: Schema.ConsolidatedGroupsLinesQuery['user']['groups'];
  groupsLoading: boolean;
  selectedGroupId: string | null;
  onSelectGroup: (groupId: string) => void;
}

interface State {
  mobileOpen: boolean;
  selectedGroupId: string;
}

const MobileOpenButton = ({ className, onClick }: { className: string; onClick: () => void }) => (
  <Fab aria-label="Add" color="primary" className={className} onClick={onClick}>
    <OpenIcon />
  </Fab>
);

class GroupsSidebar extends React.Component<Props & WithStyles<typeof styles> & WithTranslation, State> {
  readonly state: State = {
    mobileOpen: false,
    selectedGroupId: this.props.selectedGroupId || '',
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleGroupClick = (groupId: string) => () => {
    this.setState({ selectedGroupId: groupId === this.state.selectedGroupId ? '' : groupId });
  };

  handleCheckClick = () => {
    this.setState({ mobileOpen: false });
    this.props.onSelectGroup(this.state.selectedGroupId);
  };

  getGroupItems = (
    groups: Schema.ConsolidatedGroupsLinesQuery['user']['groups'],
    t: i18next.TFunction,
    classes: Record<string, string>,
  ) => {
    return groups.map((group) => (
      <React.Fragment key={group.id}>
        <ListItem
          className="rtl-text-align"
          button
          onClick={this.handleGroupClick(group.id)}
          selected={this.state.selectedGroupId === group.id}
        >
          <ListItemText
            primary={group.name}
            secondary={`${t(['line:numberOfLines'], { defaultValue: 'Number of lines' })}: ${group.lines.length} `}
          />
          {this.props.selectedGroupId === group.id ? <CircleIcon color="primary" fontSize="small" /> : null}
        </ListItem>
        <Collapse in={this.state.selectedGroupId === group.id} timeout="auto" unmountOnExit>
          <List disablePadding>
            {group.lines.filter(Types.isTruthy).map((line) => (
              <ListItem className={clsx('rtl-text-align', classes.nested)} key={line.name} button disabled>
                <ListItemText primary={line.name} primaryTypographyProps={{ color: 'textPrimary' }} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    ));
  };

  public render() {
    const { classes, groups, groupsLoading, t } = this.props;
    const HelpText = (
      <Typography variant="caption" className={clsx('rtl-direction', classes.helpText)}>
        {t(['line:moreGroupsHelp'], { defaultValue: 'Create more groups in the Administration panel' })}
      </Typography>
    );
    return (
      <>
        <Hidden lgUp implementation="css">
          <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor={'right'}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div>
              <ListSubheader style={{ display: 'flex' }}>
                {t(['line:selectGroup'], { defaultValue: 'Select group' })}
                <div className={classes.buttonGroupMobile}>
                  <Button
                    variant="contained"
                    disabled={!this.state.selectedGroupId}
                    color="primary"
                    onClick={this.handleCheckClick}
                  >
                    <DoneIcon />
                    <span className={classes.loadText}>{t(['shared:loadData'], { defaultValue: 'Load data' })}</span>
                  </Button>
                  <Button onClick={this.closeMobile}>
                    <ClearIcon />
                  </Button>
                </div>
              </ListSubheader>
              {HelpText}
              {groupsLoading || groups.length ? (
                <LoadingOrError loading={groupsLoading}>{this.getGroupItems(groups, t, classes)}</LoadingOrError>
              ) : (
                <div className={classes.noLinesContainer}>
                  <Typography className={classes.noLines}>
                    {t(['line:noLinesInAnyGroup'], {
                      defaultValue: 'There were no lines in any of the groups you have access to.',
                    })}
                  </Typography>
                </div>
              )}
            </div>
          </Drawer>
          <div className={classes.fabContainer}>
            {!this.state.mobileOpen ? <MobileOpenButton className={classes.fab} onClick={this.openMobile} /> : null}
          </div>
        </Hidden>
        <Hidden lgDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            anchor={'right'}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div>
              <div className={classes.toolbar} />
              <Divider />
              <ListSubheader style={{ display: 'flex' }}>
                {t(['line:selectGroup'], { defaultValue: 'Select group' })}
                <div className={classes.buttonGroup}>
                  <Button
                    variant="contained"
                    disabled={!this.state.selectedGroupId}
                    color="primary"
                    onClick={this.handleCheckClick}
                  >
                    <DoneIcon />
                    <span className={classes.loadText}>{t(['shared:loadData'], { defaultValue: 'Load data' })}</span>
                  </Button>
                </div>
              </ListSubheader>
              {HelpText}
              {groupsLoading || groups.length ? (
                <LoadingOrError loading={groupsLoading}>{this.getGroupItems(groups, t, classes)}</LoadingOrError>
              ) : (
                <div className={classes.noLinesContainer}>
                  <Typography className={classes.noLines}>
                    {t(['line:noLinesInAnyGroup'], {
                      defaultValue: 'There were no lines in any of the groups you have access to.',
                    })}
                  </Typography>
                </div>
              )}
              <div className={classes.toolbar} />
            </div>
          </Drawer>
        </Hidden>
      </>
    );
  }
  private closeMobile = () => this.setState({ mobileOpen: false });

  private openMobile = () => this.setState({ mobileOpen: true });
}
const enhance = compose<unknown, Props>(withTranslation(['shared', 'line']), withStyles(styles));
export default enhance(GroupsSidebar as React.ComponentType<unknown>);
