import '@blackbird/common/augmentations';
import { Theme } from '@mui/material';
import fetch from 'isomorphic-unfetch';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

export interface Summary {
  page: PageCommons;
  components: Component[];
  incidents: Incident[];
  scheduled_maintenances: Incident[];
  status: StatusCommons;
}

export interface PageCommons {
  id: string;
  name: string;
  url: string;
  time_zone: string;
  updated_at: Date;
}

export interface StatusCommons {
  indicator: string;
  description: string;
}

export interface Component {
  status: ComponentStatus;
  name: string;
  created_at: Date;
  updated_at: Date;
  position: number;
  description: string | null;
  showcase: boolean;
  id: string;
  group_id: string | null;
  page_id: string;
  group: boolean;
}

export interface Incident {}

export enum ComponentStatus {
  OPERATIONAL = 'operational',
  UNDER_MAINTENANCE = 'under_maintenance',
  DEGRADED_PERFORMANCE = 'degraded_performance',
  PARTIAL_OUTAGE = 'partial_outage',
  MAJOR_OUTAGE = 'major_outage',
}

const useStyles = makeStyles()((theme: Theme) => ({
  statusContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  statusIndicator: {
    width: '0.75rem',
    height: '0.75rem',
    borderRadius: '50%',
    backgroundColor: '#aaa',
    lineHeight: '1rem',
    marginRight: '5px',
  },
  none: {
    backgroundColor: '#2ecc71',
  },
  operational: {
    backgroundColor: '#2ecc71',
  },
  minor: {
    backgroundColor: '#f1c40f',
  },
  major: {
    backgroundColor: '#e67e22',
  },
  critical: {
    backgroundColor: '#e74c3c',
  },
  maintenance: {
    backgroundColor: '#3498db',
  },
}));

const fetchStatus = async (path: string): Promise<Summary | undefined> => {
  try {
    const response = await fetch(path);

    if (!response.ok) {
      return;
    }

    return response.json() as Promise<Summary>;
  } catch {}
};

const SystemStatus = (): JSX.Element => {
  const { t } = useTranslation(['shared']);
  const [state, setState] = useState<Summary | undefined>();
  const { classes } = useStyles();
  const onFetch = useCallback(async () => {
    setState(await fetchStatus('https://585cxbjqrwh1.statuspage.io/api/v2/status.json'));
  }, []);

  useEffect(() => {
    onFetch();

    const interval = setInterval(onFetch, (5).minutes);

    // Clear timeout if the component is unmounted
    return () => clearInterval(interval);
  }, []);

  let indicator = classes.none;
  switch (state?.status.indicator) {
    case 'operational':
      indicator = classes.operational;
      break;
    case 'minor':
      indicator = classes.minor;
      break;
    case 'major':
      indicator = classes.major;
      break;
    case 'critical':
      indicator = classes.critical;
      break;
    case 'maintenance':
      indicator = classes.maintenance;
      break;
  }

  return (
    <div className={classes.statusContainer}>
      <div className={`${classes.statusIndicator} ${indicator}`} />
      {t(['shared:statusPage'], { defaultValue: 'Status' })}
    </div>
  );
};

export default SystemStatus;
