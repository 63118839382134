import { ArrowBack } from '@mui/icons-material';
import { DateRange, PolylineOutlined, Sensors } from '@mui/icons-material';
import { CalendarSearch } from 'mdi-material-ui';

/**
 * The number of sample points we show in all data graphs. This should be the same, to
 * provide a consistent view of the data, throughout the system.
 */
export const SAMPLE_POINTS: number = 500;

/**
 * Height of menu items in dropdown menus.
 */
export const DROPDOWN_MENU_ITEM_HEIGHT: number = 48;

/**
 * The icon we use to indicate something is a line.
 */
export const LINE_ICON = PolylineOutlined;

/**
 * The icon we use to indicate something is a device.
 */
export const DEVICES_ICON = Sensors;

/**
 * The icon we use for schedules (e.g. line schedule).
 */
export const SCHEDULE_ICON = DateRange;

/**
 * The icon we use for the calendar/time picker.
 */
export const CALENDAR_PICKER_ICON = CalendarSearch;

/**
 * The icon we use for the calendar/time picker.
 */
export const NAVIGATE_BACK_BUTTON = ArrowBack;

/**
 * The number of elements per page that we show in the overview pages.
 */
export const PAGE_SIZE = 8;

export const TIMEVALUES = {
  day: (24).hours,
  week: (7).days,
  month: (30).days,
};

export const SESSION_STORAGE_LINES_NAVIGATION_OVERVIEW_SEARCH_STATE_KEY =
  'SESSION_STORAGE_LINES_NAVIGATION_OVERVIEW_SEARCH_STATE_KEY';

export const SESSION_STORAGE_DEVICES_NAVIGATION_OVERVIEW_SEARCH_STATE_KEY =
  'SESSION_STORAGE_DEVICES_NAVIGATION_OVERVIEW_SEARCH_STATE_KEY';

export const LOCAL_STORAGE_LINES_OVERVIEW_LAYOUT_CONFIG = 'LOCAL_STORAGE_LINES_OVERVIEW_LAYOUT_CONFIG';

export const LOCAL_STORAGE_REGISTER_STOPS_SENSORS = 'LOCAL_STORAGE_REGISTER_STOPS_SENSORS';

export const LOCAL_STORAGE_LINE_BATCH_OVERVIEW_PAGE_SIZE = 'LOCAL_STORAGE_LINE_BATCH_OVERVIEW_PAGE_SIZE';

export const ROLLING_24_HOURS = 1;
export const STOPS_IN_24_HOURS = 2;

export const localStorageKeyKPIS = (user: string) => {
  return `${user}-KPIS`;
};

export const localStorageKeyTargets = (user: string) => {
  return `${user}-TARGETS`;
};

export const localStorageKeyDashboardRollingType = (lineId: string) => {
  return `${lineId}_DASHBOARD_ROLLING_TYPE`;
};

export const localStorageKeyDashboardCharts = (lineId: string) => {
  return `${lineId}_DASHBOARD_CONFIGURATION`;
};

export const localStorageKeyDashboardStopSubtracts = (lineId: string) => {
  return `${lineId}_DASHBOARD_CONFIGURATION_STOP_SUBTRACTS`;
};

export const localStorageKeyDashboardStopTypes = (lineId: string) => {
  return `${lineId}_DASHBOARD_CONFIGURATION_STOP_TYPES`;
};

export const localStorageKeyDashboardIncludePlannedBatchTimes = (lineId: string) => {
  return `${lineId}_DASHBOARD_CONFIGURATION_INCLUDE_PLANNED_BATCH_TIMES`;
};

export const localStorageToggleBetweenProdMeterAndRegStops = (lineId: string) => {
  return `${lineId}_TOGGLE_PROD_METER_REG_STOPS`;
};

export const localStorageKeyDashboardShowProductionMeter = (lineId: string) => {
  return `${lineId}_DASHBOARD_CONFIGURATION_PRODUCTION_METER`;
};

export const LOCAL_STORAGE_BETA = 'BLACKBIRD_BETA';

export const HOURLY_ANNOTATION_TAGS = {
  SIGNATURE: 'signature',
  COUNTERMEASURE: 'countermeasure',
};

export const LOCAL_STORAGE_PINNED = 'PINNED';

export const COMPANIES = {
  BLACKBIRD: 'eu-west-1_aBWI4ripT',
  BLACKBIRD_STAGING: 'eu-west-1_lu59lbvt7',
  BLACKBIRD_INTEGRATION: 'eu-west-1_czfdxeBwy',
  DITSCH: 'eu-west-1_M688EnDz3',
  LUNDBECK: 'eu-west-1_rWr89Di8G',
  FERRING: 'eu-west-1_2kFPCOGXt',
  VELUX: 'eu-west-1_3HhFmTR7L',
  RADIOMETER: 'eu-west-1_DXEVX3RNB',
  MCBRIDE_DENMARK: 'eu-west-1_h1TPBWTrd',
  XELLIA: 'eu-west-1_KbRYwcvBI',
  V2TOBACCO: 'eu-west-1_Ra9pJi2OO',
  DANISHCROWN: 'eu-west-1_bxV8gUV7N',
  CORO: 'eu-west-1_k4M3gfCUR',
  CRISPY_FOOD: 'eu-west-1_3Y4sB5WQn',
  'RADIOMETER-DK': 'eu-west-1_RjzskH6Eb',
  VEGA_SALMON: 'eu-west-1_Sg0oAgOqk',
};

export const USER_IDS = {
  FINN: '27b3fe61-3f26-449d-a880-8dd2b654e1e3',
};

export const SESSION_STORAGE_TOKEN = 'SESSION_STORAGE_TOKEN';

export const COOKIE_SESSION_NAME = 'assumeSession';
