import * as React from 'react';
import { UrlObject } from 'url';

export interface LineDescriptionProperties {
  lineId: string;
  lineDescription?: string;
  lineUrl: UrlObject | { pathname: string; query?: Record<string, string | string[]> };
  refreshLine: (name: string, id: string) => void;
}

export const LineDescriptionContext = React.createContext<LineDescriptionProperties>({
  lineId: '',
  lineDescription: '',
  lineUrl: { pathname: '/' },
  refreshLine: () => undefined,
});
