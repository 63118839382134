import React from 'react';

import availableLanguages from '@/languages.json';

export interface ILanguagesContext {
  availableLanguages: typeof availableLanguages;
}

export const LanguagesContext = React.createContext<ILanguagesContext>({
  availableLanguages,
});

const Context = {
  Languages: LanguagesContext,
};

export interface WithLanguages {
  languages: ILanguagesContext;
}

export const withLanguages = (WrappedComponent: React.ComponentType<WithLanguages>) => {
  return class WithLanguagesWrapper extends React.Component {
    public render() {
      return (
        <Context.Languages.Consumer>
          {(languages) => <WrappedComponent languages={languages} {...this.props} />}
        </Context.Languages.Consumer>
      );
    }
  };
};
