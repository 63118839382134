import { Component, FunctionComponent, createContext, useCallback, useEffect, useMemo, useState } from 'react';

export interface IClockContext {
  currentTime: Date;
  start(..._: unknown[]): any;
}

const defaultValue: IClockContext = {
  currentTime: new Date(),
  start: () => undefined,
};

export const ClockContext = createContext(defaultValue);

let intervalId: ReturnType<typeof setTimeout>;

const Clock: FunctionComponent = ({ children }) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [running, setRunning] = useState(false);

  const currentTimeDate = useMemo(() => {
    return new Date(currentTime);
  }, [currentTime]);

  const tick = useCallback(() => {
    setCurrentTime(Date.now());

    return setTimeout(() => {
      intervalId = tick();
    }, (1).seconds);
  }, []);

  useEffect(() => {
    if (running) {
      intervalId = tick();
    } else {
      clearTimeout(intervalId);
    }

    return () => {
      clearTimeout(intervalId);
    };
  }, [running]);

  const start = useCallback(() => {
    setRunning(true);
  }, []);

  return (
    <ClockContext.Provider
      value={{
        currentTime: currentTimeDate,
        start,
      }}
    >
      {children}
    </ClockContext.Provider>
  );
};

export interface WithClock {
  clock: IClockContext;
}

export const withClock = (WrappedComponent: React.ComponentType<WithClock>) => {
  return class WithClockWrapper extends Component {
    public render() {
      return (
        <ClockContext.Consumer>{(clock) => <WrappedComponent clock={clock} {...this.props} />}</ClockContext.Consumer>
      );
    }
  };
};

export default Clock;
