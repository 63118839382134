import React, { FC, useEffect, useState } from 'react';

import { createContextHook } from '@/contexts';

export interface Context {
  commit: string;
  version: string;
}

export const [VersionContextProvider, useVersion] = createContextHook<Context>();

const VersionContextWrapper: FC = ({ children }) => {
  const [version, setVersion] = useState('');
  const [commit, setCommit] = useState('');

  useEffect(() => {
    if (version && commit) {
      return;
    }

    (async () => {
      const response = await fetch('/version');

      if (response.status !== 200) {
        return;
      }

      try {
        const { version, commit } = await response.json();

        setVersion(version);
        setCommit(commit);
      } catch {}
    })();
  }, []);

  return (
    <VersionContextProvider
      value={{
        commit,
        version,
      }}
    >
      {children}
    </VersionContextProvider>
  );
};

export default VersionContextWrapper;
