import { useQuery } from '@apollo/client';
import * as Schema from 'generated/graphql/schema';
import React from 'react';

import GroupsSidebar from '@/components/bars/groups-sidebar';
import { groupsAndLines } from '@/graphql/queries';

interface Properties {
  onSelectGroup(groupId: string): void;
}

const GroupsSidebarWrapper: React.FunctionComponent<Properties> = ({ onSelectGroup }) => {
  const { data, loading } = useQuery<Schema.GroupsAndLinesQuery>(groupsAndLines, {
    partialRefetch: true,
    errorPolicy: 'all',
    ssr: false,
  });

  return (
    <GroupsSidebar
      groupsLoading={loading}
      selectedGroupId={null}
      groups={data?.company?.groups || []}
      onSelectGroup={onSelectGroup}
    />
  );
};

export default GroupsSidebarWrapper;
