import { useQuery } from '@apollo/client';
import { themes } from '@blackbird/ui-base';
import { Hidden, GlobalStyles as MuiGlobalStyles } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import * as i18next from 'i18next';
import { memoize } from 'lodash';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { LineDescriptionContext } from '@/contexts/line-description';
import { IUserContext, UserContext } from '@/contexts/user';
import { me } from '@/graphql/queries';
import Clock from '@/hocs/with-clock';
import { isWidthDown } from '@/hocs/with-width';
import { useWidth } from '@/hooks';
import * as Feature from '@/lib/features';

import { emptyFeatureList, staticFeatures } from './features';
import Footer from './footer';
import Header from './header';
import NavigationDrawer from './navigation-drawer';

/**
 * Convert the feature list from a GraphQLFeatureList to a IAM FeatureList.
 */
const transformFeatures = memoize((features?: Feature.GraphQLFeatureList): Feature.FeatureMap => {
  if (process.env.WIP) {
    return staticFeatures;
  }

  if (!features) {
    return emptyFeatureList;
  }

  return Object.entries(features).reduce(
    (featureMap, [identifier, featureList]) => {
      if (identifier === '__typename') {
        return featureMap;
      }

      for (const { id, feature } of featureList as Feature.GraphQLFeatureKey[]) {
        featureMap[identifier as keyof Feature.FeatureMap][id] = feature.reduce((matrix, { key: directive, Q, M }) => {
          matrix[directive] = { Q, M };
          return matrix;
        }, {} as Feature.FeatureKeys);
      }

      return featureMap;
    },
    {
      group: {},
      line: {},
      peripheral: {},
      userPool: {},
    } as Feature.FeatureMap,
  );
});

type GlobalStylesProps = { i18n?: i18next.i18n };

const GlobalStyles: React.FC<GlobalStylesProps> = ({ i18n }) => {
  const direction = (i18n?.language ?? 'en') === 'he' ? 'rtl' : 'ltr';
  const textAlign = (i18n?.language ?? 'en') === 'he' ? 'right' : 'left';
  const spacing = (i18n?.language ?? 'en') === 'he' ? themes.light.spacing() : 0;

  return (
    <MuiGlobalStyles
      styles={{
        html: {
          backgroundColor: themes.dark.palette.primary.main,
        },
        // Target all text.
        p: {
          direction,
        },
        span: {
          direction,
        },
        // Target menus.
        // li: {
        //   direction,
        // },
        // Target input fields.
        input: {
          direction,
        },
        // Some icons and elements have explicit handling for RTL.
        '.rtl-direction': {
          direction,
        },
        '.rtl-text-align': {
          textAlign,
        },
        '.rtl-padding-right': {
          paddingRight: spacing,
        },
        '.rtl-padding-left': {
          paddingLeft: spacing,
        },
        '.rtl-margin-right': {
          marginRight: spacing,
        },
        '.rtl-margin-left': {
          marginLeft: spacing,
        },
      }}
    />
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  ieFlexboxFix: {
    display: 'flex',
    flexDirection: 'column',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
  },
  contentCompact: {
    marginLeft: 0,
    marginTop: theme.spacing(8),
  },
  contentRegular: {
    width: 'calc(100% - 72px)',
    minHeight: '100vh',
    marginTop: theme.spacing(8),
  },
  contentMultiScreens: {
    marginLeft: 0,
    marginTop: 0,
  },
  content: {
    minHeight: '100vh',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(7),
      padding: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 'calc(20% - 55px)',
      padding: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 'calc(20% - 35px)',
      padding: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
    },
  },
  multiScreensDashboardContent: {
    width: 'calc(100% - 72px)',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    marginTop: 0,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      padding: 0,
    },
  },
  openNavigationContent: {
    marginTop: theme.spacing(8),
  },
  flexibleFooter: {
    flexDirection: 'column',
    display: 'flex',
    flexGrow: 1,
    flexBasis: 'auto',
  },
}));

type Properties = {
  children: React.ReactNode;
  availableLanguages: {
    [key: string]: {
      name?: string;
      nativeName: string;
      isReferenceLanguage?: boolean;
      translated?: any;
    };
  };
  i18n?: i18next.i18n;
};

type LineInfo = {
  lineId: string;
  lineDescription: string;
  lineUrl: { pathname: string; query?: Record<string, string | string[]> };
};

const privilegeByRole = {
  [Schema.RoleType.READONLY]: 0,
  [Schema.RoleType.REGULAR]: 1,
  [Schema.RoleType.SUPER]: 2,
};

const initializePendo = ({ user }: Schema.MeQuery) => {
  if (!('pendo' in window)) {
    return;
  }

  if (typeof window.pendo !== 'object' || window.pendo === null) {
    return;
  }

  if (!('initialize' in window.pendo) || typeof window.pendo.initialize !== 'function') {
    return;
  }

  const [mostPrivilegedRole] = user.groups
    .flatMap((group) => group.role.type)
    .sort((l, r) => privilegeByRole[r] - privilegeByRole[l]);

  window.pendo.initialize({
    visitor: {
      id: user.sub,
      email: user.email,
      role: mostPrivilegedRole,
      companyId: user.company.id,
      companyName: user.company.name,
    },
  });
};

const PageLayout: React.FC<Properties> = ({ children, i18n }) => {
  const [navigationOpen, setNavigationOpen] = useState(false);

  const [lineInfo, setLineInfo] = useState<LineInfo>({
    lineId: '',
    lineDescription: '',
    lineUrl: { pathname: '/lines' },
  });

  const refreshLine = (name: string, lineId: string) => {
    if (lineInfo.lineId !== lineId) {
      setLineInfo({
        lineId,
        lineDescription: name,
        lineUrl: { pathname: '/line', query: { lineId, tab: 'register-stops' } },
      });
    }
  };

  const { classes, cx } = useStyles();
  const router = useRouter();
  const { multiScreens = '', layout = '' } = router.query as { multiScreens: string; layout: string };

  const width = useWidth();

  const { data, loading, error } = useQuery<Schema.MeQuery, Schema.MeQueryVariables>(me, {
    partialRefetch: true,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    onCompleted: initializePendo,
    ssr: false,
  });

  const features = data?.features ?? undefined;
  const transformedFeatures = transformFeatures(features);
  const claimsData = data?.claims;

  const user = data?.user ?? undefined;
  const fullLoading = (loading && !user) || (error && !user) ? true : false;
  const extendedUser = {
    ...(user || {
      sub: '',
      username: '',
      email: '',
      givenName: '',
      familyName: '',
      company: {
        name: '',
      },
    }),
    features: transformedFeatures,
    status: { loading, error, fullLoading },
    claims: claimsData,
  } as IUserContext;

  return (
    <>
      <GlobalStyles i18n={i18n} />
      <div className={classes.ieFlexboxFix}>
        <div>
          <UserContext.Provider value={extendedUser}>
            <LineDescriptionContext.Provider
              value={{
                lineId: lineInfo.lineId ?? '',
                lineDescription: lineInfo.lineDescription ?? '',
                lineUrl: lineInfo.lineUrl ?? {},
                refreshLine,
              }}
            >
              <div className={classes.flexibleFooter}>
                <div className={classes.appFrame}>
                  <ThemeProvider theme={themes.dark}>
                    {!multiScreens && (
                      <Header isNavigationOpen={navigationOpen} openNavigation={() => setNavigationOpen(true)} />
                    )}
                  </ThemeProvider>
                  {!multiScreens && (
                    <NavigationDrawer
                      open={navigationOpen}
                      toggleOpen={() => setNavigationOpen((s) => !s)}
                      companyId={extendedUser.company.id}
                      features={extendedUser.features}
                      headerLabel={<Hidden mdUp>{extendedUser.email}</Hidden>}
                      hideWhenClosed={layout === 'compact' || isWidthDown('md', width)}
                    />
                  )}
                  <Clock>
                    <div
                      className={cx(classes.content, {
                        [classes.contentRegular]: !multiScreens && layout !== 'compact' && !navigationOpen,
                        [classes.contentCompact]: multiScreens === 'true' || (layout === 'compact' && !navigationOpen),
                        [classes.openNavigationContent]: navigationOpen,
                        [classes.contentMultiScreens]: multiScreens === 'true',
                      })}
                    >
                      {children}
                    </div>
                  </Clock>
                </div>
              </div>
            </LineDescriptionContext.Provider>
          </UserContext.Provider>
          <ThemeProvider theme={themes.dark}>
            <Footer />
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default PageLayout;
