import { Backdrop, Button, Container, DialogActions, Link, NoSsr, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useCookie from '@/hooks/use-cookie';

export interface Properties {}

const OutdatedBrowser: FunctionComponent<Properties> = () => {
  // @ts-ignore
  const isIE = useMemo(() => typeof window !== 'undefined' && /*@cc_on!@*/ (false || !!document.documentMode), []);

  const [dismissed, setDismissed] = useCookie('outdatedBrowserDismissed', `${!isIE}`);
  const [open, setOpen] = useState(dismissed === 'false');

  const theme = useTheme();

  const [t] = useTranslation(['shared']);

  const onClose = useCallback(() => {
    setOpen(false);
    setDismissed(`${true}`, (7).days);
  }, []);

  if (!open) {
    return null;
  }

  return (
    <NoSsr>
      <Backdrop
        style={{ zIndex: theme.zIndex.drawer + 1, color: 'white', flexDirection: 'column' }}
        open
        onClick={onClose}
      >
        <Container maxWidth="sm">
          <Typography>
            <Trans i18nKey="shared:outdatedBrowser">
              You are using an outdated browser which may negatively impact your user experience. Microsoft will begin
              to sunset Internet Explorer 11 for its products starting 2021.
              <br />
              You can read more at{' '}
              <Link
                underline="always"
                color="secondary"
                target="_blank"
                href="https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666"
              >
                Microsoft 365 apps say farewell to Internet Explorer 11 and Windows 10 sunsets Microsoft Edge Legacy
              </Link>
              . Please upgrade to a modern browser, e.g. Edge, Chrome or Firefox, for the best experience.
            </Trans>
          </Typography>
          <DialogActions>
            <Button color="secondary" onClick={onClose}>
              {t(['shared:dismiss'], 'Dismiss')}
            </Button>
          </DialogActions>
        </Container>
      </Backdrop>
    </NoSsr>
  );
};

export default OutdatedBrowser;
