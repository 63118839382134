import * as Schema from 'generated/graphql/schema';

export enum DashboardItemType {
  BATCH = 'BATCH',
  SHIFT = 'SHIFT',
}

export enum ChartSpan {
  HOURS_24 = 'HOURS_24',
  CURRENT = 'CURRENT',
  NONE = 'NONE',
}

export enum StopType {
  BATCH_NON_PROD = 'BATCH_NON_PROD',
  DOWNTIME = 'DOWNTIME',
  NO_ACT = 'NO_ACT',
  NON_PROD = 'NON_PROD',
  UNREGISTERED = 'UNREGISTERED',
}

export type ShiftTimeData = {
  timeRange?: Schema.TimeRange;
  samples?: (Schema.Sample | null)[] | null;
  stops?: (Pick<Schema.Stop, 'timeRange'> | null)[];
  stats?: {
    data: {
      produced?: number | null | undefined;
      lineStatus?: Schema.LineStatus | null | undefined;
    };
  };
};

export type STOP = Pick<Schema.Stop, 'isMicroStop' | 'timeRange' | 'duration' | 'ongoing' | 'comment'> & {
  stopCause?: Omit<Schema.StopCause, 'order' | 'deleted'> | null | undefined;
};
