const isObjectLike = <T extends {}>(input: unknown): input is T => typeof input === 'object' && input !== null;

const omitDeep = <T extends {}, K extends string>(input: T | T[], needle: K): Omit<T, K> | Array<Omit<T, K>> => {
  if (!isObjectLike(input)) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map((item) => omitDeep(item, needle)) as Array<Omit<T, K>>;
  }

  const isRawObject = input.constructor.name === 'Object';
  if (!isRawObject) {
    return input;
  }

  const { [needle]: _trimmed, ...rest } = input;

  return Object.entries(rest).reduce((out, [key, value]) => {
    if (key in rest) {
      // This is fine - I know what I'm doing - TypeScript does not.
      // @ts-ignore
      out[key] = omitDeep(value, needle) as unknown as any;
    }
    return out;
  }, {}) as unknown as Omit<T, K>;
};

export default omitDeep;
