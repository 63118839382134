import * as I18n from 'i18next';
import { useTranslation as origUseTranslation } from 'react-i18next';

import { NonNullableTFunction } from '@/types/i18n';

type UseTranslation = typeof origUseTranslation;

export function useTranslation(...args: Parameters<UseTranslation>) {
  const returnValue = origUseTranslation(...args);

  const [t, i18n, ready] = returnValue;

  const translate = function <
    A extends Parameters<typeof t>[0],
    B extends Parameters<typeof t>[1],
    C extends Parameters<typeof t>[2],
  >(...innerArgs: [A, B?, C?]) {
    return t(...innerArgs) || '';
  };

  const ret = [translate, i18n, ready] as [NonNullableTFunction, I18n.i18n, boolean] & {
    t: NonNullableTFunction;
    i18n: I18n.i18n;
    ready: boolean;
  };

  ret.t = translate;
  ret.i18n = i18n;
  ret.ready = ready;

  return ret;
}
