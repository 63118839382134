import { LocalStorage, SessionStorage, Snackbar } from '@blackbird/ui-base';
import { FC } from 'react';

import ApolloProvider from '@/components/apollo-provider';
import VersionContextWrapper from '@/hooks/use-version';
import WebSerialAPI from '@/lib/serial/web-serial-api';

const StaticContextProviders: FC = ({ children }) => {
  return [ApolloProvider, WebSerialAPI, VersionContextWrapper, Snackbar, LocalStorage, SessionStorage].reduce(
    (Providers, Provider) => <Provider>{Providers}</Provider>,
    <>{children}</>,
  );
};

export default StaticContextProviders;
