import React from 'react';

import { UserContext } from '@/contexts/user';
import * as Types from '@/types';

export interface WithUser {
  user: Types.ExtendedUser;
}

export const withUser = (WrappedComponent: React.ComponentType<WithUser>) => {
  return class WithUserWrapper extends React.Component {
    public render() {
      return (
        <UserContext.Consumer>{(user) => <WrappedComponent user={user as any} {...this.props} />}</UserContext.Consumer>
      );
    }
  };
};
